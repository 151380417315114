<clr-modal [clrModalOpen]="modalOpen">
  <div class="modal-body">
    <form [formGroup]="form" clrForm clrLayout="vertical">
      <section class="title">
        <h3 class="welcome">{{'DASHBOARD.PASSWORD_RESET.HEADLINE' | translate}}</h3>
      </section>
      <div>
        <clr-input-container>
          <label>{{'DASHBOARD.PASSWORD_RESET.NEW_PASSWORD' | translate}}</label>
          <input type="password" name="password" formControlName="password" clrInput/>
          <clr-control-error>{{'DASHBOARD.PASSWORD_RESET.PASSWORD_INVALID_MESSAGE' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-input-container [class.clr-error]="form.errors?.passwordMismatch && form.get('repeatPassword').touched" >
          <label>{{'DASHBOARD.PASSWORD_RESET.REPEAT_NEW_PASSWORD' | translate}}</label>
          <input type="password" name="repeatPassword" formControlName="repeatPassword" clrInput/>
          <clr-control-error>{{'DASHBOARD.PASSWORD_RESET.REPEAT_PASSWORD_INVALID' | translate}}</clr-control-error>
        </clr-input-container>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="button-wrapper">
      <button type="button" class="btn btn-regular" (click)="modalOpen = false">{{'COMMON.CANCEL' | translate}}</button>
      <button [disabled]="form.invalid" type="button" class="btn btn-primary" (click)="doReset()">
        {{'DASHBOARD.PASSWORD_RESET.SAVE_PASSWORD_BUTTON' | translate}}
      </button>
    </div>
  </div>
</clr-modal>
