<portal-container [centered]="false">
  <div class="home-content">
    <h1>{{'MERCHANT.HOME.HEADLINE' | translate}}</h1>
    <p>{{'MERCHANT.HOME.TEXT' | translate}}</p>
  </div>
  <div class="home-steps">
    <h4>{{'MERCHANT.HOME.STEPS.HEADLINE' | translate}}</h4>
    <div class="step-section">
      <div class="section-header">
        <clr-icon shape="store" class="is-solid" size="36"></clr-icon>
        <h4>{{'MERCHANT.HOME.STEPS.INFORMATION.HEADLINE' | translate}}</h4>
      </div>
      <p>{{'MERCHANT.HOME.STEPS.INFORMATION.TEXT' | translate}}</p>
      <button class="btn btn-primary btn-link" routerLink="/merchant/profile">
        {{'MERCHANT.HOME.STEPS.INFORMATION.BUTTON_TEXT' | translate}}
      </button>
    </div>
    <div class="step-section">
      <div class="section-header">
        <clr-icon shape="block" class="is-solid" size="36"></clr-icon>
        <h4>{{'MERCHANT.HOME.STEPS.PRODUCTS.HEADLINE' | translate}}</h4>
      </div>
      <p>{{'MERCHANT.HOME.STEPS.PRODUCTS.TEXT' | translate}}</p>
      <button class="btn btn-primary btn-link" routerLink="/merchant/products">
        {{'MERCHANT.HOME.STEPS.PRODUCTS.BUTTON_TEXT' | translate}}
      </button>
    </div>
  </div>
</portal-container>
