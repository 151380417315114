<clr-modal (clrModalOpenChange)="modalClosed()" [clrModalOpen]="modalOpen">
  <div class="modal-body">
    <form [formGroup]="passwordForm" clrForm clrLayout="vertical">
      <section class="title">
        <h3 class="welcome">{{'MERCHANT.ACCOUNT.CHANGE_PASSWORD' | translate}}</h3>
      </section>
      <div>
        <clr-input-container>
          <label>{{'MERCHANT.ACCOUNT.NEW_PASSWORD' | translate}}</label>
          <input type="password" name="password" formControlName="password" clrInput/>
          <clr-control-error>{{'MERCHANT.REGISTER.PASSWORD_INVALID' | translate}}</clr-control-error>
        </clr-input-container>
        <clr-input-container [class.clr-error]="passwordForm.errors?.passwordMismatch && passwordForm.get('repeatPassword').touched" >
          <label>{{'MERCHANT.ACCOUNT.CONFIRM_PASSWORD' | translate}}</label>
          <input type="password" name="repeatPassword" formControlName="repeatPassword" clrInput/>
          <clr-control-error>{{'MERCHANT.REGISTER.REPEAT_PASSWORD_INVALID' | translate}}</clr-control-error>
        </clr-input-container>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div class="button-wrapper">
      <button type="button" class="btn btn-regular" (click)="modalClosed()">{{'COMMON.CANCEL' | translate}}</button>
      <button [disabled]="passwordForm.invalid" type="button" class="btn btn-primary" (click)="changeMail()">{{'COMMON.SAVE' | translate}}</button>
    </div>
  </div>
</clr-modal>
