<h1>{{ 'MERCHANT.VOUCHERS.TITLE' | translate }}</h1>

<!-- Datagrid Table -->
<clr-datagrid (clrDgRefresh)="refresh()" [clrDgLoading]="loading">
  <clr-dg-placeholder>{{ 'MERCHANT.VOUCHERS.NO_VOUCHERS_FOUND' | translate }}</clr-dg-placeholder>

  <!-- Columns -->
  <clr-dg-column>{{ 'MERCHANT.VOUCHERS.CREATED_AT' | translate }}</clr-dg-column>
  <clr-dg-column>{{ 'MERCHANT.VOUCHERS.NAME' | translate }}</clr-dg-column>
  <clr-dg-column>{{ 'MERCHANT.VOUCHERS.CODE' | translate }}</clr-dg-column>
  <clr-dg-column>{{ 'MERCHANT.VOUCHERS.PRICE' | translate }}</clr-dg-column>
  <clr-dg-column>{{ 'MERCHANT.VOUCHERS.REDEEMED_AT' | translate }}</clr-dg-column>
  <clr-dg-column></clr-dg-column>

  <!-- Row -->
  <clr-dg-row *ngFor="let voucher of vouchers">
    <clr-dg-cell>{{ voucher.createdAt.date | date }}</clr-dg-cell>
    <clr-dg-cell>{{ voucher.name }}</clr-dg-cell>
    <clr-dg-cell>{{ voucher.code }}</clr-dg-cell>
    <clr-dg-cell>{{ voucher.value.price }}</clr-dg-cell>
    <clr-dg-cell>
      <span *ngIf="!voucher.redeemedAt"> - </span>
      <span *ngIf="voucher.redeemedAt">{{ voucher.redeemedAt.date | date }}</span>
    </clr-dg-cell>
    <clr-dg-cell>
      <a *ngIf="null === voucher.redeemedAt" (click)="redeemVoucher(voucher)" title="{{ 'MERCHANT.VOUCHERS.REDEEM_VOUCHER' | translate }}">
        <clr-icon shape="event"></clr-icon> </a
      >
    </clr-dg-cell>
  </clr-dg-row>

  <!-- Footer -->
  <clr-dg-footer>
    {{ fromVoucher }} - {{ tillVoucher }} of
    {{ total }} {{ 'MERCHANT.VOUCHERS.TITLE' | translate}}
    <clr-dg-pagination
      [(clrDgPage)]="currentPage"
      #pagination
      [clrDgTotalItems]="total"
    ></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
