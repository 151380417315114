import { Component } from '@angular/core';

@Component({
  selector: 'portal-organization-home',
  templateUrl: './organization-home.component.html',
  styleUrls: ['./organization-home.component.scss']
})
export class OrganizationHomeComponent {

}
