<h1>
  {{'MERCHANT.ORDER.HEADLINE' | translate}}
</h1>
<span>
  {{'MERCHANT.ORDER.SUB_HEADLINE' | translate}}
</span>

<clr-datagrid (clrDgRefresh)="refresh()" [clrDgLoading]="loading">
  <clr-dg-placeholder>{{'MERCHANT.ORDER.PLACEHOLDER' | translate}}</clr-dg-placeholder>

  <clr-dg-column>{{'COMMON.DATE' | translate}}</clr-dg-column>
  <clr-dg-column>{{'COMMON.ORDER_NUMBER' | translate}}</clr-dg-column>
  <clr-dg-column>{{'MERCHANT.ORDER.CUSTOMER_NAME' | translate}}</clr-dg-column>
  <clr-dg-column>{{'COMMON.SUM' | translate}}</clr-dg-column>
  <clr-dg-column>{{'COMMON.STATE' | translate}}</clr-dg-column>
  <clr-dg-column>{{'COMMON.ACTIONS' | translate}}</clr-dg-column>
  <clr-dg-row *ngFor="let order of orders">
    <clr-dg-cell>{{order.orderDateTime | localeDate}}</clr-dg-cell>
    <clr-dg-cell>{{order.orderNumber}}</clr-dg-cell>
    <clr-dg-cell>{{order.orderCustomer.firstName}} {{order.orderCustomer.lastName}}</clr-dg-cell>
    <clr-dg-cell>{{order.price.totalPrice}}€</clr-dg-cell>
    <clr-dg-cell>
      <ng-container *ngIf="
            order.stateMachineState.technicalName === 'open'
            && order.transactions[order.transactions.length-1].stateMachineState.technicalName === 'open'
          "
      >
        <span>{{'COMMON.OPEN' | translate}}</span>
      </ng-container>
      <ng-container
          *ngIf="
            order.stateMachineState.technicalName === 'open'
            && order.transactions[order.transactions.length-1].stateMachineState.technicalName === 'paid'
          "
      >
        <span>{{'COMMON.PAID' | translate}}</span>
      </ng-container>
      <ng-container *ngIf="order.stateMachineState.technicalName === 'completed'">
        <span>{{'MERCHANT.ORDER.COMPLETED' | translate}}</span>
      </ng-container>
    </clr-dg-cell>
    <clr-dg-cell>
      <a (click)="openDetails(order)" aria-haspopup="true" class="tooltip tooltip-xs">
        <clr-icon shape="pencil"></clr-icon>
        <span class="tooltip-content">{{'COMMON.DETAILS' | translate}}</span>
      </a>
      <ng-container
          *ngIf="
            order.stateMachineState.technicalName === 'open'
            && order.transactions[order.transactions.length-1].stateMachineState.technicalName === 'open'
          "
      >
        <a (click)="markOrderAsPaid(order)" aria-haspopup="true" class="tooltip tooltip-sm">
          <clr-icon shape="coin-bag" class="is-solid"></clr-icon>
          <span class="tooltip-content">{{'MERCHANT.ORDER.PAID' | translate}}</span>
        </a>
      </ng-container>
      <ng-container
          *ngIf="
            order.stateMachineState.technicalName === 'open'
            && order.transactions[order.transactions.length-1].stateMachineState.technicalName === 'paid'
          "
      >
        <a (click)="markOrderAsCompleted(order)" aria-haspopup="true" class="tooltip tooltip-sm">
          <clr-icon size="24" shape="check-circle"></clr-icon>
          <span class="tooltip-content">{{'MERCHANT.ORDER.MARK_COMPLETED' | translate}}</span>
        </a>
      </ng-container>
    </clr-dg-cell>
  </clr-dg-row>
  <clr-dg-footer>
    {{ fromOrder }} - {{ tillOrder }} {{'COMMON.OF' | translate}}
    {{ total }} {{'COMMON.ORDERS' | translate}}
    <clr-dg-pagination
      [(clrDgPage)]="currentPage"
      #pagination
      [clrDgTotalItems]="total"
    ></clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
